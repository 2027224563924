import { AxiosPromise } from 'axios';

import { BaseApi, RequestArgs } from './base';
import { createRequestFunction } from './common';
import { LoginData, LoginFetchParams, ProductInfoData, RegisterFetchParams, UserInfo } from './interface';

export default class UserApi extends BaseApi {
  login(params: LoginFetchParams): AxiosPromise<LoginData> {
    const localVarAxiosArgs: RequestArgs = {
      url: `/auth/login`,
      options: {
        method: 'POST',
        params: params,
      },
    };
    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  register(params: RegisterFetchParams): AxiosPromise<LoginData> {
    const localVarAxiosArgs: RequestArgs = {
      url: `/auth/register`,
      options: {
        method: 'POST',
        params: params,
      },
    };
    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  getUserInfo(): AxiosPromise<UserInfo> {
    const localVarAxiosArgs: RequestArgs = {
      url: `/core/getUserInfo`,
      options: {
        method: 'GET',
      },
    };
    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  getProductInfo(url: string, serviceId: string): AxiosPromise<ProductInfoData> {
    const localVarAxiosArgs: RequestArgs = {
      url: `/core/getProductInfo`,
      options: {
        method: 'POST',
        params: {
          url,
          serviceId,
        },
      },
    };
    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  generateCSV(data: ProductInfoData, serviceId: string, sourceLang: string) {
    const localVarAxiosArgs: RequestArgs = {
      url: `/core/downloadCSV`,
      options: {
        method: 'POST',
        params: {
          serviceId,
          sourceLang,
        },
        data,
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  translateProduct(
    data: ProductInfoData,
    serviceId: string,
    sourceLang?: string,
    targetLang?: string,
    needGpt?: boolean,
  ): AxiosPromise<ProductInfoData> {
    const localVarAxiosArgs: RequestArgs = {
      url: `/core/getTranslatedInfo`,
      options: {
        method: 'POST',
        params: {
          serviceId,
          sourceLang,
          targetLang,
          needGpt,
        },
        data,
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }
}
