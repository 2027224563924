import { Col, InputNumber, Row } from 'antd';
import { VariantData } from 'api/interface';

interface ProductVariantProps {
  variant: VariantData;
  onChange: (value: VariantData) => void;
}

const ProductVariant = ({ variant }: ProductVariantProps) => {
  // const handleChangeVariant = (key: keyof VariantData, value: React.ReactNode) => {
  //   onChange({
  //     ...variant,
  //     [key]: value,
  //   });
  // };

  const { option1_value, option2_value, price, compare_at_price } = variant;

  return (
    <div>
      <p>
        {option1_value} / {option2_value}
      </p>
      <Row gutter={16}>
        <Col span={8} className='flex flex-col'>
          <label>Price</label>
          <InputNumber value={price} className='w-full' readOnly />
        </Col>
        <Col span={8} className='flex flex-col'>
          <label>Compare at Price</label>
          <InputNumber value={compare_at_price} className='w-full' readOnly />
        </Col>
      </Row>
    </div>
  );
};

export default ProductVariant;
