import { BaseOptionType } from 'antd/es/select';

export const originOptions: BaseOptionType[] = [
  {
    label: 'Youtube',
    value: 'Youtube',
  },
  {
    label: 'Discord',
    value: 'Discord',
  },
  {
    label: 'Facebook',
    value: 'Facebook',
  },
  {
    label: 'Instagram',
    value: 'Instagram',
  },
  {
    label: 'Tiktok',
    value: 'Tiktok',
  },
  {
    label: 'Friend',
    value: 'Friend',
  },
  {
    label: 'Weibo',
    value: 'Weibo',
  },
  {
    label: 'Wechat',
    value: 'Wechat',
  },
  {
    label: 'Bilibili',
    value: 'Bilibili',
  },
  {
    label: 'Other',
    value: 'Other',
  },
];

export const langOptions: BaseOptionType[] = [
  {
    label: 'Afrikaans',
    value: 'af',
  },
  {
    label: 'Albanian',
    value: 'sq',
  },
  {
    label: 'Amharic',
    value: 'am',
  },
  {
    label: 'Arabic',
    value: 'ar',
  },
  {
    label: 'Armenian',
    value: 'hy',
  },
  {
    label: 'Assamese',
    value: 'as',
  },
  {
    label: 'Aymara',
    value: 'ay',
  },
  {
    label: 'Azerbaijani',
    value: 'az',
  },
  {
    label: 'Bambara',
    value: 'bm',
  },
  {
    label: 'Basque',
    value: 'eu',
  },
  {
    label: 'Belarusian',
    value: 'be',
  },
  {
    label: 'Bengali',
    value: 'bn',
  },
  {
    label: 'Bhojpuri',
    value: 'bho',
  },
  {
    label: 'Bosnian',
    value: 'bs',
  },
  {
    label: 'Bulgarian',
    value: 'bg',
  },
  {
    label: 'Catalan',
    value: 'ca',
  },
  {
    label: 'Cebuano',
    value: 'ceb',
  },
  {
    label: 'Chichewa',
    value: 'ny',
  },
  {
    label: 'Chinese (simplified)',
    value: 'zh-CN',
  },
  {
    label: 'Chinese (traditional)',
    value: 'zh-TW',
  },
  {
    label: 'Corsican',
    value: 'co',
  },
  {
    label: 'Croatian',
    value: 'hr',
  },
  {
    label: 'Czech',
    value: 'cs',
  },
  {
    label: 'Danish',
    value: 'da',
  },
  {
    label: 'Dhivehi',
    value: 'dv',
  },
  {
    label: 'Dogri',
    value: 'doi',
  },
  {
    label: 'Dutch',
    value: 'nl',
  },
  {
    label: 'English',
    value: 'en',
  },
  {
    label: 'Esperanto',
    value: 'eo',
  },
  {
    label: 'Estonian',
    value: 'et',
  },
  {
    label: 'Ewe',
    value: 'ee',
  },
  {
    label: 'Filipino',
    value: 'tl',
  },
  {
    label: 'Finnish',
    value: 'fi',
  },
  {
    label: 'French',
    value: 'fr',
  },
  {
    label: 'Frisian',
    value: 'fy',
  },
  {
    label: 'Galician',
    value: 'gl',
  },
  {
    label: 'Georgian',
    value: 'ka',
  },
  {
    label: 'German',
    value: 'de',
  },
  {
    label: 'Greek',
    value: 'el',
  },
  {
    label: 'Guarani',
    value: 'gn',
  },
  {
    label: 'Gujarati',
    value: 'gu',
  },
  {
    label: 'Haitian creole',
    value: 'ht',
  },
  {
    label: 'Hausa',
    value: 'ha',
  },
  {
    label: 'Hawaiian',
    value: 'haw',
  },
  {
    label: 'Hebrew',
    value: 'iw',
  },
  {
    label: 'Hindi',
    value: 'hi',
  },
  {
    label: 'Hmong',
    value: 'hmn',
  },
  {
    label: 'Hungarian',
    value: 'hu',
  },
  {
    label: 'Icelandic',
    value: 'is',
  },
  {
    label: 'Igbo',
    value: 'ig',
  },
  {
    label: 'Ilocano',
    value: 'ilo',
  },
  {
    label: 'Indonesian',
    value: 'id',
  },
  {
    label: 'Irish',
    value: 'ga',
  },
  {
    label: 'Italian',
    value: 'it',
  },
  {
    label: 'Japanese',
    value: 'ja',
  },
  {
    label: 'Javanese',
    value: 'jw',
  },
  {
    label: 'Kannada',
    value: 'kn',
  },
  {
    label: 'Kazakh',
    value: 'kk',
  },
  {
    label: 'Khmer',
    value: 'km',
  },
  {
    label: 'Kinyarwanda',
    value: 'rw',
  },
  {
    label: 'Konkani',
    value: 'gom',
  },
  {
    label: 'Korean',
    value: 'ko',
  },
  {
    label: 'Krio',
    value: 'kri',
  },
  {
    label: 'Kurdish (kurmanji)',
    value: 'ku',
  },
  {
    label: 'Kurdish (sorani)',
    value: 'ckb',
  },
  {
    label: 'Kyrgyz',
    value: 'ky',
  },
  {
    label: 'Lao',
    value: 'lo',
  },
  {
    label: 'Latin',
    value: 'la',
  },
  {
    label: 'Latvian',
    value: 'lv',
  },
  {
    label: 'Lingala',
    value: 'ln',
  },
  {
    label: 'Lithuanian',
    value: 'lt',
  },
  {
    label: 'Luganda',
    value: 'lg',
  },
  {
    label: 'Luxembourgish',
    value: 'lb',
  },
  {
    label: 'Macedonian',
    value: 'mk',
  },
  {
    label: 'Maithili',
    value: 'mai',
  },
  {
    label: 'Malagasy',
    value: 'mg',
  },
  {
    label: 'Malay',
    value: 'ms',
  },
  {
    label: 'Malayalam',
    value: 'ml',
  },
  {
    label: 'Maltese',
    value: 'mt',
  },
  {
    label: 'Maori',
    value: 'mi',
  },
  {
    label: 'Marathi',
    value: 'mr',
  },
  {
    label: 'Meiteilon (manipuri)',
    value: 'mni-Mtei',
  },
  {
    label: 'Mizo',
    value: 'lus',
  },
  {
    label: 'Mongolian',
    value: 'mn',
  },
  {
    label: 'Myanmar',
    value: 'my',
  },
  {
    label: 'Nepali',
    value: 'ne',
  },
  {
    label: 'Norwegian',
    value: 'no',
  },
  {
    label: 'Odia (oriya)',
    value: 'or',
  },
  {
    label: 'Oromo',
    value: 'om',
  },
  {
    label: 'Pashto',
    value: 'ps',
  },
  {
    label: 'Persian',
    value: 'fa',
  },
  {
    label: 'Polish',
    value: 'pl',
  },
  {
    label: 'Portuguese',
    value: 'pt',
  },
  {
    label: 'Punjabi',
    value: 'pa',
  },
  {
    label: 'Quechua',
    value: 'qu',
  },
  {
    label: 'Romanian',
    value: 'ro',
  },
  {
    label: 'Russian',
    value: 'ru',
  },
  {
    label: 'Samoan',
    value: 'sm',
  },
  {
    label: 'Sanskrit',
    value: 'sa',
  },
  {
    label: 'Scots gaelic',
    value: 'gd',
  },
  {
    label: 'Sepedi',
    value: 'nso',
  },
  {
    label: 'Serbian',
    value: 'sr',
  },
  {
    label: 'Sesotho',
    value: 'st',
  },
  {
    label: 'Shona',
    value: 'sn',
  },
  {
    label: 'Sindhi',
    value: 'sd',
  },
  {
    label: 'Sinhala',
    value: 'si',
  },
  {
    label: 'Slovak',
    value: 'sk',
  },
  {
    label: 'Slovenian',
    value: 'sl',
  },
  {
    label: 'Somali',
    value: 'so',
  },
  {
    label: 'Spanish',
    value: 'es',
  },
  {
    label: 'Sundanese',
    value: 'su',
  },
  {
    label: 'Swahili',
    value: 'sw',
  },
  {
    label: 'Swedish',
    value: 'sv',
  },
  {
    label: 'Tajik',
    value: 'tg',
  },
  {
    label: 'Tamil',
    value: 'ta',
  },
  {
    label: 'Tatar',
    value: 'tt',
  },
  {
    label: 'Telugu',
    value: 'te',
  },
  {
    label: 'Thai',
    value: 'th',
  },
  {
    label: 'Tigrinya',
    value: 'ti',
  },
  {
    label: 'Tsonga',
    value: 'ts',
  },
  {
    label: 'Turkish',
    value: 'tr',
  },
  {
    label: 'Turkmen',
    value: 'tk',
  },
  {
    label: 'Twi',
    value: 'ak',
  },
  {
    label: 'Ukrainian',
    value: 'uk',
  },
  {
    label: 'Urdu',
    value: 'ur',
  },
  {
    label: 'Uyghur',
    value: 'ug',
  },
  {
    label: 'Uzbek',
    value: 'uz',
  },
  {
    label: 'Vietnamese',
    value: 'vi',
  },
  {
    label: 'Welsh',
    value: 'cy',
  },
  {
    label: 'Xhosa',
    value: 'xh',
  },
  {
    label: 'Yiddish',
    value: 'yi',
  },
  {
    label: 'Yoruba',
    value: 'yo',
  },
  {
    label: 'Zulu',
    value: 'zu',
  },
];
