import { Navigate, Route, Routes } from 'react-router-dom';

import Service from '../pages/Service';
import UserCenter from '../pages/UserCenter';

const StoreCopyIndex = () => (
  <Routes>
    <Route index path='/userCenter' element={<UserCenter />} />
    <Route index path='/service/:serviceId' element={<Service />} />
    <Route path='*' element={<Navigate to='/userCenter' replace />} />
  </Routes>
);

export default StoreCopyIndex;
