import { Input, Modal } from 'antd';
import { useEffect, useState } from 'react';

interface InputUrlModalProps {
  open: boolean;
  onClose: () => void;
  onUpdateUrl: (url: string) => void;
}

const InputUrlModal = ({ open, onClose, onUpdateUrl }: InputUrlModalProps) => {
  const [url, setUrl] = useState('');

  useEffect(() => {
    if (open) setUrl('');
  }, [open]);

  const handleOk = () => {
    onClose();
    onUpdateUrl(url);
  };

  return (
    <Modal title='URL' open={open} onCancel={onClose} onOk={handleOk}>
      <Input value={url} onChange={e => setUrl(e.currentTarget.value)} />
    </Modal>
  );
};

export default InputUrlModal;
