import 'antd/dist/reset.css';
import './App.css';

import api from 'api';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import Home from './pages/Home';
import StoreCopyIndex from './routes';

const App = () => {
  return (
    <BrowserRouter>
      {!api.isAuthentication ? (
        <Routes>
          <Route index path='/' element={<Home />} />
          <Route path='*' element={<Navigate to='/' replace />} />
        </Routes>
      ) : (
        <Routes>
          <Route path='/*' element={<StoreCopyIndex />} />
        </Routes>
      )}
    </BrowserRouter>
  );
};

export default App;
