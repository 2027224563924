import { Editor } from '@tinymce/tinymce-react';
import { Button, Card, Col, Image, Input, InputNumber, Row, Space, Tag } from 'antd';
import { ProductInfoData, VariantData } from 'api/interface';

import ProductVariant from '../ProductVariant';
import styles from './styles.module.css';

interface ProductCardProps {
  title: string;
  enableDownload: boolean;
  product?: ProductInfoData;
  setProduct: React.Dispatch<React.SetStateAction<ProductInfoData | undefined>>;
  onDownload: () => void;
}

const ProductCard = ({ title, enableDownload, product, setProduct, onDownload }: ProductCardProps) => {
  const handleChangeProduct = (key: keyof ProductInfoData, value: React.ReactNode) => {
    const variants = [...(product?.variants || [])];
    if (key === 'price') {
      variants.forEach(item => {
        item.price = Number(value);
      });
    } else if (key === 'compare_at_price') {
      variants.forEach(item => {
        item.compare_at_price = Number(value);
      });
    } else if (key === 'title') {
      if (product != undefined) {
        product.handle = String(value)
          .replace(/[^a-zA-Z0-9]/g, ' ')
          .replace(/\s\s+/g, ' ')
          .replaceAll(' ', '-')
          .toLowerCase();
      }
    }
    setProduct({
      ...product,
      variants,
      [key]: value,
    });
  };

  const handleChangeVariants = (index: number, value: VariantData) => {
    if (!product || !product.variants) return;

    product.variants[index] = value;
    setProduct({ ...product });
  };

  return (
    <Card
      className='flex flex-col flex-1'
      hoverable
      title={title}
      extra={
        <>
          {enableDownload && (
            <Button disabled={!product || !enableDownload} onClick={onDownload}>
              Download CSV
            </Button>
          )}
        </>
      }
    >
      {product && (
        <div className={styles['card-content']}>
          <Image.PreviewGroup>
            {product?.images?.map((imageSrc, index) => (
              <Image key={index} src={imageSrc} width={150} />
            ))}
          </Image.PreviewGroup>

          <p>Product title</p>
          <Input value={product?.title} onChange={e => handleChangeProduct('title', e.currentTarget.value)} />

          <p>Product handle/slug (a-z, 0-9 and -)</p>
          <Input value={product?.handle} readOnly />

          <p>Product Vendor</p>
          <Input value={product?.vendor} onChange={e => handleChangeProduct('vendor', e.currentTarget.value)} />

          <Row gutter={16}>
            <Col span={12} className='flex flex-col'>
              <p>Average Product price</p>
              <InputNumber value={product?.price} className='w-full' onChange={value => handleChangeProduct('price', value)} />
            </Col>
            <Col span={12} className='flex flex-col'>
              <p>Average Product compare at price</p>
              <InputNumber
                value={product?.compare_at_price}
                className='w-full'
                onChange={value => handleChangeProduct('compare_at_price', value)}
              />
            </Col>
          </Row>

          <h3>Product description</h3>
          <Editor
            init={{ menubar: false, height: 500 }}
            apiKey={'3vwa2i6oi7mg62eslb0qclxwrvcgpmcjmmjxl6c5d4mbfd0k'}
            value={product?.body}
            onEditorChange={value => handleChangeProduct('body', value)}
          />

          <h3>Product variants</h3>
          {product?.variants?.map((variant, index) => (
            <ProductVariant key={index} variant={variant} onChange={value => handleChangeVariants(index, value)} />
          ))}

          <h3>Product Tags</h3>
          <Space wrap>
            {product?.tags?.map(tag => (
              <Tag key={tag}>{tag}</Tag>
            ))}
          </Space>
        </div>
      )}
    </Card>
  );
};

export default ProductCard;
