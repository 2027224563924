import { HomeOutlined } from '@ant-design/icons';
import { Button, Select, Spin } from 'antd';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import api from '../../api';
import { ProductInfoData } from '../../api/interface';
import InputUrlModal from '../../components/InputUrlModal';
import ProductCard from '../../components/ProductCard';
import { langOptions } from '../../utils';
import styles from './styles.module.css';

const Service = () => {
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [originalProduct, setOriginalProduct] = useState<ProductInfoData>();
  const [translateProduct, setTranslateProduct] = useState<ProductInfoData>();
  const [lang, setLang] = useState<string>();
  const [sourceLang, setSourceLang] = useState<string>();
  const [url, setUrl] = useState<string>('');
  const { serviceId } = useParams<{ serviceId: string }>();
  const navigate = useNavigate();
  const handleUpdateUrl = (value: string) => {
    if (!serviceId) return;
    setLoading(true);
    setUrl(value);
    setLang(undefined);
    setTranslateProduct(undefined);
    api.UserApi.getProductInfo(value, serviceId)
      .then(res => {
        const productData = res.data;
        if (productData.title != undefined) {
          productData.handle = productData.title
            .replace(/[^a-zA-Z0-9]/g, ' ')
            .replace(/\s\s+/g, ' ')
            .replaceAll(' ', '-')
            .toLowerCase();
        }
        setOriginalProduct(productData);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleTranslate = (data?: ProductInfoData) => {
    if (!data) return;
    if (!serviceId) return;
    setLoading(true);
    api.UserApi.translateProduct(data, serviceId, sourceLang, lang, false)
      .then(res => {
        const productData = res.data;
        if (productData.title != undefined) {
          productData.handle = productData.title
            .replace(/[^a-zA-Z0-9]/g, ' ')
            .replace(/\s\s+/g, ' ')
            .replaceAll(' ', '-')
            .toLowerCase();
        }
        setTranslateProduct(productData);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleGenerateWithAi = (data?: ProductInfoData) => {
    if (!data) return;
    if (!serviceId) return;
    setLoading(true);
    api.UserApi.translateProduct(data, serviceId, sourceLang, lang, true)
      .then(res => {
        const productData = res.data;
        if (productData.title != undefined) {
          productData.handle = productData.title
            .replace(/[^a-zA-Z0-9]/g, ' ')
            .replace(/\s\s+/g, ' ')
            .replaceAll(' ', '-')
            .toLowerCase();
        }
        setTranslateProduct(productData);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDownload = (data?: ProductInfoData) => {
    if (!data) return;
    if (!lang) return;
    if (!serviceId) return;
    setLoading(true);
    api.UserApi.generateCSV(data, serviceId, lang)
      .then(res => {
        const blob = new Blob([res.data], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('href', url);
        a.setAttribute('download', 'download.csv');
        a.click();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleGoHome = () => {
    navigate('/userCenter');
  };

  return (
    <>
      <Spin size='large' spinning={loading} tip={'Wait a few seconds, we are generating a text with a high conversion rate, NOT REFRESH!'}>
        <div className={styles['content']}>
          <ProductCard
            title='Original Product'
            enableDownload={false}
            product={originalProduct}
            setProduct={setOriginalProduct}
            onDownload={() => {
              console.log('Download Not Available');
            }}
          />
          <div className={styles['middle-content']}>
            <Button type='primary' className='input-url-btn main-btn' onClick={handleGoHome}>
              <HomeOutlined /> Back to UserCenter
            </Button>
            <Button type='primary' className='input-url-btn main-btn' onClick={() => setOpenModal(true)}>
              Input URL
            </Button>
            <p>Source Language:</p>
            <Select
              showSearch
              className='w-full'
              options={[{ value: 'auto', label: 'Auto' }, ...langOptions]}
              defaultValue={'auto'}
              placeholder='Select source language'
              value={sourceLang}
              onChange={value => setSourceLang(value)}
              filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
            />
            <p>Target Language:</p>
            <Select
              showSearch
              className='w-full'
              disabled={!url}
              placeholder='Select target language'
              options={langOptions}
              value={lang}
              onChange={value => setLang(value)}
              filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
            />
            <Button type='primary' disabled={!url || !lang} className='main-btn' onClick={() => handleTranslate(originalProduct)}>
              {serviceId === '71fa1333-f337-488d-a6b9-ef4d42a34de9' ? 'Translate + AI Description' : 'Translate Product'}
            </Button>
            {serviceId === 'a8c07584-6be6-4125-9877-7b6959381442' && (
              <>
                <Button type='primary' disabled={!url || !lang} className='main-btn' onClick={() => handleGenerateWithAi(originalProduct)}>
                  Generate Description By AI
                </Button>
              </>
            )}
          </div>
          <ProductCard
            title='Translated Product'
            enableDownload={true}
            product={translateProduct}
            setProduct={setTranslateProduct}
            onDownload={() => handleDownload(translateProduct)}
          />
        </div>
      </Spin>

      <InputUrlModal open={openModal} onClose={() => setOpenModal(false)} onUpdateUrl={handleUpdateUrl} />
    </>
  );
};

export default Service;
