import { Button, ConfigProvider, Input, message, Select, Spin } from 'antd';
import Logo from 'images/logo.png';
import { useState } from 'react';

import api from '../../api';
import { originOptions } from '../../utils';
import styles from './styles.module.css';

const Home = () => {
  const [loading, setLoading] = useState(false);
  const [register, setRegister] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rePassword, setRePassword] = useState('');
  const [origin, setOrigin] = useState();

  const handleLogin = async () => {
    if (!isValidEmail(email)) {
      message.error('Please input valid email!');
      return;
    }
    setLoading(true);
    api.UserApi.login({ email, password })
      .then(res => {
        const { access_token, token_type } = res.data;

        localStorage.setItem('user_access_token', access_token);
        localStorage.setItem('user_token_type', token_type);
        window.location.reload();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const isValidEmail = (email: string) => {
    // Regular expression for a basic email validation
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    // Test the email against the regex
    return emailRegex.test(email);
  };

  const handleRegister = async () => {
    if (password === undefined || password === '') {
      message.error('Please input password!');
      return;
    }
    if (password !== rePassword) {
      message.error('Please input the same passwords!');
      return;
    }

    if (!isValidEmail(email)) {
      message.error('Please input valid email!');
      return;
    }

    if (origin == undefined) {
      message.error('Please select the origin!');
      return;
    }

    setLoading(true);
    api.UserApi.register({ email, password, origin })
      .then(res => {
        const { access_token, token_type } = res.data;

        localStorage.setItem('access_token', access_token);
        localStorage.setItem('token_type', token_type);
        window.location.reload();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Spin size='large' spinning={loading}>
      <>
        <div className={styles['main-content']}>
          <div className={styles['left-content']}>
            <div className={styles['header-content']}>
              <a href='https://www.storecopy.ai'>
                <img src={Logo} />
              </a>
            </div>
            <div className='stage'>
              <div className='layer'></div>
              <div className='layer'></div>
              <div className='layer'></div>
              <div className='layer'></div>
              <div className='layer'></div>
              <div className='layer'></div>
              <div className='layer'></div>
              <div className='layer'></div>
              <div className='layer'></div>
              <div className='layer'></div>
              <div className='layer'></div>
              <div className='layer'></div>
              <div className='layer'></div>
              <div className='layer'></div>
              <div className='layer'></div>
              <div className='layer'></div>
              <div className='layer'></div>
              <div className='layer'></div>
              <div className='layer'></div>
              <div className='layer'></div>
            </div>
          </div>
          <div className={styles['login-content']}>
            <div className={styles['title']}>
              <span>Welcome To StoreCopy!</span>
            </div>

            <div className={styles['border']} />

            {register ? (
              <>
                <ConfigProvider theme={{ token: { colorTextPlaceholder: '#a6a6a6', controlHeightLG: 48 } }}>
                  <Input
                    size='large'
                    placeholder='Please input your email'
                    prefix={<div className={styles['input-prefix']}>Email</div>}
                    value={email}
                    onChange={e => setEmail(e.currentTarget.value)}
                    onPressEnter={handleRegister}
                    style={{ marginBottom: 20 }}
                  />
                  <Input.Password
                    size='large'
                    placeholder='Please input your password'
                    prefix={<div className={styles['input-prefix']}>Password</div>}
                    value={password}
                    onChange={e => setPassword(e.currentTarget.value)}
                    onPressEnter={handleRegister}
                    style={{ marginBottom: 20 }}
                  />
                  <Input.Password
                    size='large'
                    placeholder='Please input your password again'
                    prefix={<div className={styles['input-prefix']}>Re-Password</div>}
                    value={rePassword}
                    onChange={e => setRePassword(e.currentTarget.value)}
                    onPressEnter={handleRegister}
                    style={{ marginBottom: 20 }}
                  />
                  <Select
                    className={styles['origin-select']}
                    size='large'
                    placeholder='Please select where you know our product'
                    options={originOptions}
                    value={origin}
                    onChange={value => setOrigin(value)}
                  />
                  <ConfigProvider theme={{ token: { controlHeightLG: 52, fontSizeLG: 18 } }}>
                    <Button size='large' type='primary' onClick={handleRegister}>
                      Register
                    </Button>
                  </ConfigProvider>
                </ConfigProvider>
                <Button className={styles['btn-switch']} type='link' onClick={() => setRegister(false)}>
                  Already have account? Please login here!
                </Button>
              </>
            ) : (
              <>
                <ConfigProvider theme={{ token: { colorTextPlaceholder: '#a6a6a6', controlHeightLG: 48 } }}>
                  <Input
                    size='large'
                    placeholder='Please input your email'
                    prefix={<div className={styles['input-prefix']}>Email</div>}
                    value={email}
                    onChange={e => setEmail(e.currentTarget.value)}
                    onPressEnter={handleLogin}
                    style={{ marginBottom: 20 }}
                  />
                  <Input.Password
                    size='large'
                    placeholder='Please input your password'
                    prefix={<div className={styles['input-prefix']}>Password</div>}
                    value={password}
                    onChange={e => setPassword(e.currentTarget.value)}
                    onPressEnter={handleLogin}
                    style={{ marginBottom: 20 }}
                  />
                  <ConfigProvider theme={{ token: { controlHeightLG: 52, fontSizeLG: 18 } }}>
                    <Button size='large' type='primary' onClick={handleLogin}>
                      Login
                    </Button>
                  </ConfigProvider>
                </ConfigProvider>
                <Button className={styles['btn-switch']} type='link' onClick={() => setRegister(true)}>
                  Do you need a new account? Please register here!
                </Button>
              </>
            )}
          </div>
        </div>
      </>
    </Spin>
  );
};

export default Home;
