import { message } from 'antd';
import axios, { AxiosError } from 'axios';

import UserApi from './user';

const BASE_PATH = process.env.REACT_APP_API_URL || '';

const globalAxios = axios.create({
  baseURL: BASE_PATH,
});

const user_access_token = localStorage.getItem('user_access_token');
const user_token_type = localStorage.getItem('user_token_type');

globalAxios.interceptors.request.use(requestConfig => {
  const newConfig = requestConfig;
  newConfig.headers.Authorization = `${user_token_type} ${user_access_token}`;
  return newConfig;
});

globalAxios.interceptors.response.use(
  response => {
    return response;
  },
  (error: AxiosError) => {
    if (error.response?.status === 401) {
      localStorage.removeItem('user_access_token');
      localStorage.removeItem('user_token_type');
      window.location.reload();
    }
    if (error.response?.status === 400) {
      message.error(error.response?.data as string);
    } else {
      message.error('SERVER ERROR');
    }
    return Promise.reject(error);
  },
);

const api = {
  UserApi: new UserApi(globalAxios),
  isAuthentication: !!user_access_token,
};

export default api;
