import { faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, ConfigProvider, Row, Spin } from 'antd';
import classnames from 'classnames';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import api from '../../api';
import { ServiceData, UserInfo } from '../../api/interface';
import LogoImage from '../../images/logo.png';
import PyamentImage from '../../images/payment.jpg';
import styles from './styles.module.css';

const UserCenter = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [userInfo, setUserInfo] = useState<UserInfo>();

  useEffect(() => {
    getUserInfo();
  }, []);

  const getUserInfo = () => {
    setLoading(true);
    api.UserApi.getUserInfo()
      .then(res => {
        setUserInfo(res.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleGetStarted = (service: ServiceData) => {
    navigate(`/service/${service.serviceId}`);
  };

  const handleLogout = () => {
    localStorage.clear();
    window.location.reload();
  };

  return (
    <>
      <Spin size='large' spinning={loading}>
        <div className={styles['layout-header']}>
          <img src={LogoImage} className={styles['layout-header-logo']} />
          <div className={styles['layout-header-title']}>Hello, {userInfo?.userEmail}!</div>
          <Button
            className={styles['btn-logout']}
            size='large'
            icon={<FontAwesomeIcon icon={faRightFromBracket} />}
            onClick={handleLogout}
          />
        </div>
        <div className={styles['content']}>
          <div className={styles['heading']}>
            <div className={styles['heading-title']}>Choose the plan that’s right for you</div>
          </div>
          <Row gutter={32} justify='center'>
            {userInfo?.services?.map((service, index) => (
              <Col xs={24} md={12} lg={8} key={index} className={styles['card-col']}>
                <div className={classnames(styles['card'], !service.membership?.valid ? styles['card-invalid'] : null)}>
                  <div className={styles['card-heading']}>
                    <div className={styles['card-heading-title']}>{service.serviceName}</div>
                    {service.serviceLogo && (
                      <div className={styles['card-heading-logo']} dangerouslySetInnerHTML={{ __html: service.serviceLogo }} />
                    )}
                  </div>
                  <div className={styles['card-content']}>
                    <div className={styles['service-price']}>
                      <span>${service.servicePrice} USD/mo</span>
                      <del className={styles['before-price']}>${service.beforePrice} USD/mo</del>
                    </div>
                    <div className={styles['service-details']}>
                      <p>🤖 Auto description generation with AI with high conversion rate</p>
                      <p>✅ Copy in 1 click all {service.serviceName}’s store</p>
                      <p>✅ Choose the target language and translate in 1 click</p>
                      <p>✅ Auto-generation of SKUs</p>
                      <p>✅ Import images/gifs/videos in 1 click</p>
                      <p>✅ Import variants of {service.serviceName}’s products in 1 click</p>
                      <p className={styles['price-hightlight']}>🔥 Activate multiple functions at once to get a Discount</p>
                    </div>
                    {service.membership?.valid && (
                      <>
                        <ConfigProvider theme={{ token: { controlHeightLG: 60, colorPrimary: '#007a5a', fontSizeLG: 18 } }}>
                          <Button type='primary' size='large' onClick={() => handleGetStarted(service)}>
                            <strong>Get Started</strong>
                          </Button>
                        </ConfigProvider>
                        <div className={styles['valid-until']}>
                          Valid Until: {dayjs(service.membership.validUntil).format('MM/DD/YYYY')}
                        </div>
                      </>
                    )}
                  </div>
                  {!service.membership?.valid && (
                    <div className={styles['card-invalid-content']}>
                      <img src={PyamentImage} className={styles['payment-img']} />
                      <div>Please add wechat to get one day free trial!</div>
                    </div>
                  )}
                </div>
              </Col>
            ))}
          </Row>
        </div>
      </Spin>
    </>
  );
};

export default UserCenter;
